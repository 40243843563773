import { h } from "preact";
const PhoneNumber = (props) => {
  let { title, value, onChange } = props;

  return (
    <div>
      {title && <label>{title}</label>}
      <input
        type={"text"}
        value={value}
        placeholder="Номер телефона"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default PhoneNumber;
