import {h} from "preact";
import {useState, useEffect} from "preact/hooks";
import QRCode from 'qrcode';

import style from "./style.css";


function downloadSVG(r, name) {
  const svgContent = r

  // Create a Blob object from the SVG content
  const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });

  // Create an anchor element and attach the blob as its href
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = (name || '100dat')+ '.svg';

  // Trigger the download
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
}

function SVGQR({text, name}) {
  
  let [svg, setSvg] = useState();

  useEffect(() => {
    QRCode.toString(text, { type: 'svg' }).then(r => {
      // Your SVG content as a string
      downloadSVG(r, name || text)

      document.body.innerHTML = svg;
      document.body.style.maxWidth = '100vh'
      document.body.style.padding = '0'
      document.body.style.marginTop = '0'
      document.body.style.marginBottom = '0'
      document.body.style.overflow = 'hidden'

      setSvg(r)
    })
  })

  return <div>
    <div dangerouslySetInnerHTML={{ __html: svg }} />
  </div>
}

function SVGWrap () {
  let href = window.location.pathname;
  
  console.log('hreffff', href)
  let urls = '/' + href.split('/').filter(it => it).at(-2)
  if ((href === '/qr') || (href === '/qr.svg')) {
    urls = ''
  }
  
  return <div>
    <SVGQR text={'https://100dat.ru' + urls} name={urls.replace('/', '')}></SVGQR>
  </div>
}


export default SVGWrap;