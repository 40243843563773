import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import Loading from "./../../components/loading";
import Getter from "./../../components/getter";
import { Link } from "preact-router/match";

// Note: `user` comes from the URL, courtesy of our router
const Profile = () => {
  const [data, setData] = useState(Getter.getProfile());

  let list = [
    { key: "firstName", label: "Имя" },
    { key: "serName", label: "Фамилия" },
    { key: "className", label: "Класс" },
    { key: "telephone", label: "Телефон" },
  ];

  return (
    <div>

      <div className="card animChild noBackground" style={{marginTop: '-7px'}}>
          <div className="card card_purple mb card_title">
              <h1>Профиль</h1>
          </div>
        {list.map((it) => {
          let key = it.key;
          return (
            <div>
              <small>{it.label}</small>
              <input
                placeholder={it.label}
                key={key}
                value={data[key]}
                onKeyUp={(e) => {
                  let upData = { ...data, [key]: e.target.value };
                  console.log("updata", upData);
                  setData(upData);
                  Getter.setProfile(upData);
                }}
              ></input>
            </div>
          );
        })}
        <button>Сохранить</button>
      </div>
    </div>
  );
};

export default Profile;
