import {h} from "preact";
import {useState, useEffect} from "preact/hooks";

import style from "./style.css";
import Getter from "./../../components/getter";
import StoryDetails from "./../storyDetails";
import PhoneNumber from "../../components/PhoneNumber";
// import { MuiTelInput } from 'mui-tel-input'
// import { Button } from '@mui/material';
const Home = () => {
        let [data, setData] = useState(Getter.getExam());
        const [phone, setPhone] = useState((Getter.getProfile() || {}).telephone);
        const [name, setName] = useState((Getter.getProfile() || {}).firstName);

        let leng = (data.items || []).length || 1;
        let selectedInd = ((data.selectedInd || 0) + leng) % leng;

        let item = data.items[selectedInd];
        let {isAnswer, isCorrectAnswer} = item;

        const handleChange = (e) => {
            setPhone(e);
        };

        function onSetData(data, woTryFinish) {
            let uData = Getter.setExam(data, woTryFinish);
            setData({...uData});
        }

        useEffect(() => {
            if (!Getter.isExamFail(data)) {
                console.log("ура-------------------------");
            }
            console.log("test");
        }, [data]);

        function sendData(phone) {

            let msg = `------------------

*Тест пройден*

Имя: ${name}

Телефон: ${phone}

Сайт: ${window.location.href} 

`
            const url = `https://api.telegram.org/bot5887034845:AAEuPZ4EqYSG6wD8i3qUA-uxRsL9DIfjwUg/sendMessage?chat_id=-905852275&text=${msg}`;

            console.log("qqqqq url", url);
            const Http = new XMLHttpRequest();
            Http.open("GET", encodeURI(url));
            Http.send();

            Http.onreadystatechange = (e) => {
              Getter.initExam((data) => {
                // setData(data);
                window.location.href = '/finish'
                return;
              });
              
            }


        }


        return (
            <>
                {!data.isFinished && (
                    <div class={" card card_purple mb"}>
                        <h1>
                        <img src="/assets/imgs/Logo.svg" alt="100dat.ru" width={40} height={40} /> {Math.min(selectedInd + 1, data.count)} / {data.count}
                            {!data.isFinished && (
                                <div className="pull-right">
                                    {[0, 1, 2].map((it, ind) => {
                                        let isWrong = ind < data.wrongCount;
                                        return (
                                            <img
                                                src={"/assets/imgs/heart" + (isWrong ? "2" : "") + ".svg"}
                                                style={{
                                                    width: "25px",
                                                    marginRight: "5px",
                                                    opacity: isWrong ? ".2" : ".8"
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </h1>
                    </div>
                )}
                {!data.isFinished && (
                    <>
                        <QuizItem
                            item={item}
                            onNext={() => {
                                data.selectedInd++;
                                onSetData(data);
                            }}
                            onChange={({isCorrectAnswer, answerInd}) => {
                                data.items[selectedInd].isAnswer = true;
                                data.items[selectedInd].isCorrectAnswer = isCorrectAnswer;
                                data.items[selectedInd].answerInd = answerInd;
                                setData({...data});
                                setTimeout(() => {
                                    if (isCorrectAnswer) {
                                        data.selectedInd++;
                                    }
                                    onSetData(data, !isCorrectAnswer);
                                }, 500);
                            }}
                        ></QuizItem>

                    </>
                )}

                {data.isFinished && (
                    <div className="card animChild noBackground">
                        {Getter.isExamFail(data) && (
                            <>
                                <div className="imageWrapper mb">
                                    <img
                                        src="../assets/imgs/congrats-2.png"
                                        alt="Тест не пройден"
                                    />
                                </div>
                                <h2 className={'mb'}>Упс, тест не пройден!</h2>
                                <div className={'mb'}>Кол-во ошибок: {data.wrongCount}</div>
                            </>
                        )}
                        {!Getter.isExamFail(data) && (
                            <>
                                <div className="imageWrapper mb">
                                    <img src="../assets/imgs/congrats-1.png" alt="congrats"/>
                                </div>
                                <h2 className={'mb'}>Поздравляем!</h2>
                                <div className="cardParagraph mb">
                                    Ты успешно прошел квиз. Заполни форму для получения приза.
                                </div>
                            </>
                        )}
                        {!Getter.isExamFail(data) && (<>
                            <div style={{width: "100%"}}>
                                <small>Имя</small>
                                <input value={name} onChange={e => setName(e.target.value)}></input>
                            </div>
                            <div style={{width: "100%"}}>
                                <small>Телефон</small>
                                <PhoneNumber value={phone} onChange={handleChange}></PhoneNumber>
                            </div>

                        </>)}
                        {!Getter.isExamFail(data) && (
                            <div
                                className="mainLink"
                                onClick={() => {
                                    sendData(phone)
                                    // Getter.initExam((data) => {
                                    //   setData(data);
                                    // });
                                }}
                            >
                                ОТПРАВИТЬ
                                <img src="../assets/icons/svg/arrow-down.svg" alt="Отправить"/>
                            </div>
                        )}
                        {Getter.isExamFail(data) && (
                            <div
                                className="mainLink"
                                onClick={() => {
                                    Getter.initExam((data) => {
                                        setData(data);
                                    });
                                }}
                            >
                                Пройти тест еще раз
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }
;

function QuizItem({item, onChange, onNext}) {
    let [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        });
    }, [(item || {}).url]);
    if (loading) {
        return <></>;
    }
    let {answerInd, isAnswer, isCorrectAnswer} = item;
    return (
        <div
            className={
                (isAnswer ? style.answered : " ") + " card animChild noBackground mb"
            }
        >
            <div className="card card_green mb card_title">
                <h2>{item.name}</h2>
            </div>
            <hr/>
            <div className="quizWrapper">
                {item.variations.map((it, ind) => {
                    return (
                        <div
                            className={
                                style.vItem +
                                " " +
                                (it.isCorrect && isAnswer ? style.correct : "") +
                                " " +
                                (answerInd == ind ? style.vAnswered : "")
                            }
                            onClick={() => {
                                !isAnswer &&
                                onChange &&
                                onChange({isCorrectAnswer: it.isCorrect, answerInd: ind});
                            }}
                        >
                            {it.name}{global.localStorage.getItem('showCorrect') && it.isCorrect ? '.' : ''} 
                        </div>
                    );
                })}
            </div>
            {item.isAnswer && !item.isCorrectAnswer && (
                <>
                    <hr/>
                    <div
                        onClick={() => {
                            onNext();
                        }}
                        className="mainLink"
                    >
                        Следующий вопрос
                        <img src="../assets/icons/svg/arrow-down.svg"/>
                    </div>
                </>
            )}
        </div>
    );
}

export default Home;
