import { h } from 'preact';
import { Router, Route } from 'preact-router';
import DynamicStyle from './dynamicStyle';
import Footer from './footer';

// Code-splitting is automated for `routes` directory
import About from '../routes/about';
import Profile from '../routes/profile';
import StoriesList from '../routes/storiesList';
import Quiz from '../routes/quiz';
import StoryDetails from '../routes/storyDetails';
import Done from "../routes/done/done";
import QR from "../routes/qr/QR";



// const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// if (true) {
//     const metaStatusBar = document.createElement('meta');
//     metaStatusBar.name = "apple-mobile-web-app-status-bar-style";
//     metaStatusBar.content = "white";
//     document.head.appendChild(metaStatusBar);

//     // Add other meta tags as needed...
// }

const App = () => (
	<div id="app">
		
		<DynamicStyle></DynamicStyle>
		<Footer></Footer>
		<main>
			<Router>
				<StoriesList path="/" />
				<StoryDetails path="/:url" />
				<Profile path="/profile" />
				<About path="/about" />
				<Quiz path="/quiz" />
				<Done path="/finish" />
				<QR path="/:query/qr" />
				<QR path="/qr" />
			</Router>
		</main>

	</div>
);

export default App;
