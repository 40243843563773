import {h} from "preact";
import {useEffect, useState} from "preact/hooks";
import Loading from "../../components/loading";
import Getter from "../../components/getter";

import style from "./style.css";
import {Link} from "preact-router/match";

const List = (props) => {
    let {url, woBack} = props || {};
    let [open, setOpen] = useState(false);
    let [item, setItem] = useState({});
    let [nextUrl, setNextUrl] = useState('/');

    let [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        Getter.getStory(url, (data, nextUrl) => {
            console.log("qqqqq datatata ta stget dta", data);
            setNextUrl(nextUrl)
            setItem(data);
            setTimeout(() => {
              setLoading(false);
            }, 10)
        });
    }, [url]);

    if (loading) {
        return <Loading></Loading>;
    }

    function pub(txt) {
        return (
            "<div>" +
            txt
                .split("\n")
                .map((it) => {
                    let count = ((it.match(/^\#/gi) || [])[0] || "").length;
                    if (count) {
                        count = count + 1;
                        return `<h${count}>${it.slice(count)}</h${count}>`;
                    }
                    return it;
                })
                .join("<div></div>") +
            "</div>"
        );
    }

    return (
        <div className='animChild mb'>
            <div className={woBack ? "cardImg mb" : "cardImg mb"} >
                {!woBack && (
                    <>
                        <Link href={"/"}

                        >
                            <div className="arrowWrapper">
                                <img src="../assets/icons/svg/arrow-left.svg" alt="Вернуться"/>
                            </div>
                        </Link>
                    </>
                )}
                <div className={'cover'} style={{ backgroundImage: `url('${item.img}')` }}></div>
                <div>{item.name}</div>

            </div>

            <div className={(woBack ? " " : "card ") + "animChild mb"}>
                <div dangerouslySetInnerHTML={{__html: pub(item.shortDesc)}} style={{    fontSize: '18px',
    lineHeight: '25px'}}/>
                {/* {!open && (
                    <div
                        className="moreLink"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Показать полное описание
                    </div>
                )}
                {open && (
                    <div dangerouslySetInnerHTML={{__html: pub(item.fullDesc)}}/>
                )} */}
                
            </div>
            <Link href={'/quiz'} className={'w100 mb'}>
            <button className='w100 mb card_purple' style={{border: '1px solid var(--bcg)', color: 'white'}}>Пройти тест
            <img src="../assets/icons/svg/arrow-down-white.svg" style={{ marginTop: '-6px', float: 'right', rotate: '-90deg', color: 'white'}} /></button>
            </Link>
            <Link href={nextUrl} className={'w100 mb'}>
            <button className='w100 mb'>Следующая глава</button>
            </Link>
        </div>
    );
};

export default List;
