import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";

const Footer = () => (
  <footer class={style.footer}>
    <nav>
      <Link activeClassName={style.active} href="/">
        <div className={style.menu_item}>
          <div className={style.menu_item__icon_dates} />
          <span className={'menu_item_span'}>Даты</span>
        </div>
      </Link>

      <Link activeClassName={style.active} href="/profile">
        <div className={style.menu_item}>
          <div className={style.menu_item__icon_profile} />
          <span className={'menu_item_span'}>Профиль</span>
        </div>
      </Link>
      <Link activeClassName={style.active} href="/quiz">
        <div className={style.menu_item}>
          <div className={style.menu_item__icon_quiz} />
          <span className={'menu_item_span'}>Квиз</span>
        </div>
      </Link>
      <Link activeClassName={style.active} href="/about">
        <div className={style.menu_item}>
          <div className={style.menu_item__icon_about} />
          <span className={'menu_item_span'}>О проекте</span>
        </div>
      </Link>
    </nav>
  </footer>
);

export default Footer;
