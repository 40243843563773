import {Link} from "preact-router/match";

const Done = () => {
    return (
        <div className="card animChild noBackground">
            <div className="imageWrapper mb">
                <img src="../assets/imgs/congrats-2.png" alt="Молодец!"/>
            </div>
            <h2 className={'mb'}>Молодец!</h2>
            <div className="cardParagraph mb">Скоро мы свяжемся с тобой!</div>
            <Link href={'/'}>
                <button>Вернуться на главную</button>
            </Link>
        </div>
    );
};

export default Done;