// import rating from './rating';
import stories from './stories';
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }
  
let timer = 1;
let Getter = {
    _get(key) {
        return JSON.parse(localStorage.getItem(key))
    },
    _set(key, value) {
        return localStorage.setItem(key, JSON.stringify(value))
    },
    stories(cb) {
        setTimeout(() => {
            cb && cb(stories)
        }, timer)
    },
    // rating(cb) {
    //     setTimeout(() => {
    //         let bestResult = this.getBestResult();
    //         bestResult.name = 'Ваш результат';
    //         bestResult.isMy = true;
    //         let _rating = rating;
    //         if (bestResult) {
    //             _rating = [...rating, bestResult].sort((a, b) => {
    //                 return a.perc >= b.perc ? -1 : a.ms >= b.ms ? -1 : 1
    //             })
    //         }
    //         cb && cb(_rating)
    //     }, timer)
    // },
    getStory(url, cb) {
        setTimeout(() => {
          let index = 0;
          let item = stories.filter((it, ind) => {
            let isOk = it.url === url;
            index = isOk ? (ind + 1) : index;
            return isOk
          })[0];

          let _url = `/${  (stories[(stories.length + index) % stories.length] || {}).url || ''}`;
          console.log("item333", item, index, _url)
            cb && cb(item, _url)
        }, timer)
    },
    getProfile() {
        return Getter._get('profile') || {}
    },
    setProfile(data) {
        Getter._set('profile', data)
    },

    calcWrongCount(data) {
        let count =0;
        let goodCount =0;
        let correctAnswerCount = 0;
        let wrongCount = 0;
        data.items.forEach(it => {
            count++;
            goodCount += (it.isAnswer) ? 1 : 0
            correctAnswerCount += (it.isCorrectAnswer ? 1 : 0)
            wrongCount += (it.isAnswer && !it.isCorrectAnswer) ? 1 : 0
        })
        data.correctAnswerCount = correctAnswerCount;
        data.count = count;
        data.answerCount = goodCount;
        data.wrongCount = wrongCount;
        return data;
    },
    pubTime(ms) {
        function pub(v) {
            return v < 10 ? '0' + v : v
        }
        let minutes = Math.round(ms / 60);
        let seconds = ms % 60;
        return (minutes ? (minutes + ' мин ') : '') + (seconds ? (pub(seconds) + ' сек') : '')
    },
    isExamWrongFail(data) {
        return data.wrongCount >= 3
    },

    isExamFail(data) {
        return (data.count - data.correctAnswerCount) >= 3;
    },
    tryFinishExam(data) {
        let isFinish = data.count === data.answerCount;
        if (isFinish) {
          
            data.perc = Math.round(100 * data.correctAnswerCount / data.count)
            return this.finishExam(data)
        }
        if (this.isExamWrongFail(data)) {
            return this.finishExam(data)
        }
        
        return data;

    },
    getBestResult() {
        return this._get('bestResult');
    },
    finishExam(data, cb) {
        data.isFinished = true;
        data.finishCd = new Date().getTime();
        data.ms = Math.round((data.finishCd - data.cd) / 1000);
        
        let bestResult = this.getBestResult() || {};
        console.log('best Result', data, bestResult)

        if ((bestResult.perc || -1) < (data.perc)) {
            console.log('BEST RESULT!1')
            data.isBestResult = true;
            bestResult = {perc : data.perc, time: data.ms};
            Getter._set('bestResult', bestResult)
        }
        // this.setExam(data);
        return data;
    },
    initExam(cb) {
        function getQuizes() {
            let N = 25;
            let items = stories.reduce((acc, it) => {
                return [...acc, ...it.quizes.map(it2 => {
                    return {...it2, url: it.url, variations: shuffleArray(it2.variations)}
                })]
            }, [])
            return shuffleArray(items).splice(0, N);
        }
        let res = { items: getQuizes(), cd: new Date().getTime(), selectedInd: 0 };
        Getter.setExam(res)
        cb && cb(res);
        return res;
    },
    getExam() {
        return Getter._get('exam') || Getter.initExam()
    },
    setExam(data, isWithoutFinishExam) {
        console.log('isWithoutFinishExam', isWithoutFinishExam)
        data = this.calcWrongCount(data);
        if (!isWithoutFinishExam) {
            data = this.tryFinishExam(data)
        }
        Getter._set('exam', data)
        return data;
    }
}
global.Getter = Getter;

export default Getter;
