import {h} from "preact";
import {useEffect, useState} from "preact/hooks";
import Loading from "./../../components/loading";
import Getter from "./../../components/getter";
import Button from "@mui/material/Button";

import style from "./style.css";
import {Link} from "preact-router/match";

const List = () => {
    let [items, setItems] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        Getter.stories((data) => {
            setItems(data);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading></Loading>;
    }

    console.log("items", items);

    return (
        <div className={'animChild'}>
            <h1 className="card card_green mb card_title mainLogoTitle " >Урок молодого гражданина!</h1>
            <div className="masonry ">
                <Link href="/quiz" className={'masonryItem mb'}>
                <img src="/assets/imgs/Logo.svg" alt="100dat.ru" width={'100%'} />

                    {/* <div className="card card_green" style={{color: '#1d1c1c'}}> */}
                        {/* <span>100 важных дат в истории</span>
                        <hr/>
                        Пройти тест
                        <span className={'right_info'}></span> */}
                    {/* </div> */}
                </Link>
                <Link href="/quiz" className={'masonryItem w100'}>
                        <button className={'w100'}>Пройти квиз
                        <img src="../assets/icons/svg/arrow-down.svg" style={{ marginTop: '-6px', float: 'right', rotate: '-90deg'}}> 
                          </img></button>
                </Link>

                {items.map((it, ind) => {
                    return (
                        <div className={'masonryItem mb'}>
                            <Link href={"/" + it.url} className={"box " }>
                                <div class="box-content">
                                    <div
                                        className={style.img}
                                        style={{backgroundImage: `url('../..${it.img}')`}}
                                    >
                                        {/* <img src={`../../${it.img}`} /> */}
                                        {/* <div className={style.title}>{it.name}</div> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default List;
