import {h} from "preact";
import style from "./style.css";
import {Link} from "preact-router/match";

// import { Button } from '@mui/material';
const About = () => {
    return (
        <>
            <div class="animChild">

                <div className="card card_purple mb card_title">
                    <h1>
                      Урок молодого гражданина! О проекте!</h1>
                </div>
                <div className="imageWrapper mb">
                    {/* <img src="../assets/imgs/about.png" alt="About"/> */}
                    <img src="/assets/imgs/Logo.svg" alt="100dat.ru"  />
                </div>
                <div className="card mb animChild" style={{lineHeight: '30px'}}>
                    <h3>Пройди тест и получи ценный подарок от министерства образования!</h3>
                    <div>
                    Для того чтобы стать участником этого уникального вызова и претендовать на ценный подарок, выполните следующие шаги:

<ul>
  <li>
    <strong>Изучение дат:</strong> Уделите время, чтобы внимательно прочитать и изучить все важные даты, представленные в рамках проекта "100 дат для развития знания истории учеников в школах".
  </li>
  <li>
    <strong>Тестирование:</strong> Уделите время, После изучения материалов, примите участие в тестировании. Вам предстоит ответить верно на 25 вопросов, связанных с ключевыми моментами российской истории.
  </li>
  <li>
    <strong>3 жизни:</strong> Будьте внимательны! У вас всего 3 жизни. За каждый неверный ответ вы теряете одну жизнь. Если вы потратили все три жизни, не отчаивайтесь! Просто начните тест заново и попробуйте ещё раз.
  </li>
</ul>
<hr/>
Не упустите эту замечательную возможность погрузиться в историю России и получить ценный подарок! И помните, что главное не подарок, а знания, которые вы получите в процессе участия в этом проекте. Удачи!
                    </div>
                </div>
                <Link href='/quiz' className={'w100'}>
                <button className='w100'>Пройти тест
                </button>
                </Link>
            </div>
        </>
    );
};

export default About;
